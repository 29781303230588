import { PropsWithChildren, ReactElement } from 'react'
import './surface.scss'

type Props = PropsWithChildren<{
  style?: unknown
  isMobile?: boolean
}>

const Surface = (props: Props): ReactElement => {
  const { children, isMobile } = props

  const paddingStyle = {
    padding: isMobile ? '30px' : '40px',
  }

  return (
    <div className="surface" style={paddingStyle}>
      {children}
    </div>
  )
}

export default Surface
