import React, { ReactElement } from 'react'
import ScreenRectangle from '../components/screen-rentangle/screen-rectangle'
import { Col, Container, Row } from 'react-grid-system'
import Surface from '../components/surface/surface'
import monitor from '../assets/monitor.svg'
import QRCode from 'react-qr-code'
import { Helmet } from 'react-helmet'
import useWindowDimensions from '../utils/use-window-dimension'
import qs from 'qs'
import ReactHtmlParser from 'react-html-parser'
import { BrowserView, MobileView, isMobile, isAndroid, isIOS } from 'react-device-detect'
import { useIntl } from 'react-intl'

const OnboardingScreen = (): ReactElement => {
  const { height } = useWindowDimensions()

  const phoneHeight = height * 0.9
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  const orgSettings = {
    color: '#ED252F',
    logo: 'https://images.jigx.com/motul-email-logo.svg',
    namespace: 'motul',
    appName: 'MyMotul',
    deeplink: 'mymotul.jigx.com',
    appIdIOS: 'com.jigx.mymotul.ios',
    appIdAndroid: 'com.jigx.mymotul.android',
    appStoreURL: 'https://apps.apple.com/de/app/motul-expert/id1586785373',
    playStoreURL: 'https://play.google.com/apps/internaltest/4698997042580185190',
  }

  const qrValue = `https://${orgSettings.deeplink}/onboarding/confirm-invite/?invite=${query.invite}`
  const appDeepLink = `${orgSettings.deeplink}://onboarding/confirm-invite/?invite=${query.invite}`
  const { formatMessage } = useIntl()

  const androidIntend = `intent://scan/#Intent;scheme=${appDeepLink};package=${orgSettings.appIdAndroid};end`
  const buttonValue = isIOS ? appDeepLink : androidIntend

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/safari-pinned-tab.svg" color="#ee212c`}
        />
        <link
          rel="shortcut icon"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon.ico`}
        />
        <meta
          name="apple-itunes-app"
          content={`app-id=${orgSettings.appIdIOS}, app-argument=${qrValue}`}
        />
        <meta name="msapplication-TileColor" content={orgSettings.color} />
        <meta
          name="msapplication-config"
          content={`https://images.jigx.com/favicons/${orgSettings.namespace}/browserconfig.xml`}
        />
        <meta name="theme-color" content={orgSettings.color} />
      </Helmet>
      <ScreenRectangle color={orgSettings.color} />
      <div className="screen-wrapper">
        <div className="content-container">
          <Container style={{ maxWidth: '530px' }}>
            <Row>
              <Col xs={12} className="flex--align-center flex">
                <Surface isMobile={isMobile}>
                  <div className="logo__container">
                    <img
                      className="logo__img"
                      alt={`${orgSettings.appName} logo`}
                      height={phoneHeight}
                      style={{ maxWidth: '375' }}
                      src={orgSettings.logo}
                    />
                  </div>
                  <h1>
                    {formatMessage(
                      {
                        id: 'get-started-title',
                        defaultMessage: 'Get started on {appName}',
                      },
                      {
                        appName: orgSettings.appName,
                      },
                    )}
                  </h1>
                  <BrowserView>
                    <p className="padding-bottom--large">
                      {formatMessage(
                        {
                          id: 'browser-view-introduction',
                          defaultMessage:
                            '{appName} is Motul performance right at your fingertips! Find the hottest promotions, news, contract management and much more in the new {appName} app. Start now by accepting the invite below.',
                        },
                        { appName: orgSettings.appName },
                      )}
                    </p>
                    <Row>
                      <Col xs={12} className="flex--align-center flex--justify-center flex">
                        <div className="icon flex" style={{ flexGrow: 0 }}>
                          <img src={monitor} alt="monitor icon" />
                        </div>
                        <div className="flex--align-center  flex" style={{ flexGrow: 1 }}>
                          <h2 className="margin-left--large margin-right--large">
                            {formatMessage({
                              id: 'how-to-start-desktop',
                              defaultMessage: 'How to start on the desktop?',
                            })}
                          </h2>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <ol>
                          <li className="padding-bottom--medium">
                            {formatMessage(
                              {
                                id: 'how-to-start-desktop-step-1',
                                defaultMessage:
                                  'Download the {appName} app from the Apple App Store or Google Play Store to your phone.',
                              },
                              {
                                appName: <strong>{orgSettings.appName}</strong>,
                              },
                            )}
                          </li>
                          <li className="padding-bottom--medium">
                            {ReactHtmlParser(
                              formatMessage({
                                id: 'how-to-start-desktop-step-2',
                                defaultMessage:
                                  'Scan this QR code from the desktop with your phone camera to <strong>accept your invitation.</strong>',
                              }),
                            )}
                          </li>
                        </ol>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="flex--justify-center flex">
                          <div
                            style={{
                              padding: '5px',
                              paddingBottom: 0,
                              backgroundColor: 'white',
                            }}>
                            <QRCode value={qrValue} size={200} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </BrowserView>
                  <MobileView>
                    <Row>
                      <Col xs={12} className="flex--align-center flex--justify-center flex">
                        <div className="flex--align-center  flex" style={{ flexGrow: 1 }}>
                          <h2>
                            {formatMessage({
                              id: 'how-to-start-mobile',
                              defaultMessage: 'How to start?',
                            })}
                          </h2>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <ol className="mobile-list-item">
                          <li className="padding-bottom--medium">
                            <div className="padding-bottom--medium">
                              {formatMessage({
                                id: 'how-to-start-mobile-step-1',
                                defaultMessage: 'Download the app',
                              })}
                            </div>
                            <div className="padding-bottom--large store-logo__container flex">
                              {isIOS && (
                                <a href={orgSettings.appStoreURL}>
                                  <img
                                    src="https://images.jigx.com/email-app-store.png"
                                    height="60"
                                    alt="AppStore"
                                    className="store-logo"
                                  />
                                </a>
                              )}
                              {isAndroid && (
                                <a href={orgSettings.playStoreURL}>
                                  <img
                                    src="https://images.jigx.com/email-google-play.png"
                                    height="60"
                                    alt="PlayStore"
                                    className="store-logo"
                                  />
                                </a>
                              )}
                            </div>
                          </li>
                          <li className="padding-bottom--medium">
                            {formatMessage({
                              id: 'accept-your-invitation',
                              defaultMessage: 'Accept your invitation',
                            })}
                          </li>
                        </ol>
                      </Col>
                    </Row>
                    <div>
                      <a
                        href={buttonValue}
                        className="button button-link"
                        style={{ backgroundColor: orgSettings.color }}>
                        {formatMessage({
                          id: 'button-accept-your-invitation',
                          defaultMessage: 'Accept invitation',
                        })}
                      </a>
                    </div>
                  </MobileView>
                </Surface>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="flex--justify-center padding-top--medium flex">
                <p>Powered by Jigx, Inc.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default React.memo(OnboardingScreen)
