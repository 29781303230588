import { ReactElement, useMemo } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import 'intl'
import 'intl/locale-data/jsonp/cs'
import 'intl/locale-data/jsonp/de'
import 'intl/locale-data/jsonp/en'

// See https://formatjs.io/docs/polyfills/intl-getcanonicallocales/
import '@formatjs/intl-getcanonicallocales/polyfill'

// See https://formatjs.io/docs/polyfills/intl-locale/
import '@formatjs/intl-locale/polyfill'

// See https://formatjs.io/docs/polyfills/intl-pluralrules
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/cs' // locale-data for en
import '@formatjs/intl-pluralrules/locale-data/de' // locale-data for en
import '@formatjs/intl-pluralrules/locale-data/en' // locale-data for en

// See https://formatjs.io/docs/polyfills/intl-numberformat/
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/cs' // locale-data for en
import '@formatjs/intl-numberformat/locale-data/de' // locale-data for en
import '@formatjs/intl-numberformat/locale-data/en'

import messages from '../intl/messages' // locale-data for en

interface Props {
  children: ReactElement
}

type LangOptions = 'de' | 'en'

interface Options {
  locale: LangOptions
  messages?: Record<string, string>
}

const useIntlMessages = (options: Options): Record<string, string> => {
  return useMemo(() => {
    const localeMessages = messages[options.locale]
    const enMessages = messages.en

    return {
      ...enMessages,
      ...localeMessages,
      ...options.messages,
    }
  }, [options.locale, options.messages])
}
// Default lang by browser settings
const lang = navigator.language

const IntlProvider = (props: Props): ReactElement => {
  // Current locale based on browser language
  const locale = lang.substr(0, 2) as LangOptions
  const messages = useIntlMessages({
    locale,
  })

  console.log('selected language: ', locale)

  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {props.children}
    </ReactIntlProvider>
  )
}

export default IntlProvider
